/* eslint-disable */
/* @ts-ignore */
import React, { useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Form, FormProps, Input, InputGroup } from "rsuite";

interface ISearchInputProps extends FormProps {
  placeholder?: string;
  value: any;
  onChange?: (e?: any) => void;
  onReset?: () => void;
}

const SearchInput: React.FC<ISearchInputProps> = React.memo(
  ({ placeholder = "جستجو", onChange, onReset, value, ...restProps }) => {
    useEffect(() => {}, []);

    return (
      <Form fluid className={`w-full transition-all ts-input-group`}>
        <InputGroup inside className="w-full h-12 outline-none border-gray-100 rounded-xl ts-shadow">
          <Input
            disabled={restProps.disabled}
            value={value}
            onChange={onChange}
            className="h-full outline-none border-none rounded-2xl placeholder:text-gray-200"
            placeholder={placeholder}
          />
          <InputGroup.Button
            onClick={onReset}
            className={`${value.length === 0 ? "hidden" : ""} h-full px-0 bg-transparent hover:bg-transparent`}
          >
            <IoMdClose size={19} className={` transition-colors duration-200 -mt-1`} />
          </InputGroup.Button>
          <InputGroup.Button type="submit" className="h-full left-0 bg-transparent hover:bg-transparent">
            <AiOutlineSearch size={23} className={`ts-gray-03 transition-colors duration-200 -mt-1`} />
          </InputGroup.Button>
        </InputGroup>
      </Form>
    );
  },
);

export { SearchInput };

const styles = {};
